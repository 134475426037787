@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('AktivGroteskTrial-Light.eot');
    src: url('AktivGroteskTrial-Light.eot?#iefix') format('embedded-opentype'),
        url('AktivGroteskTrial-Light.woff2') format('woff2'),
        url('AktivGroteskTrial-Light.woff') format('woff'),
        url('AktivGroteskTrial-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('AktivGroteskTrial-Regular.eot');
    src: url('AktivGroteskTrial-Regular.eot?#iefix') format('embedded-opentype'),
        url('AktivGroteskTrial-Regular.woff2') format('woff2'),
        url('AktivGroteskTrial-Regular.woff') format('woff'),
        url('AktivGroteskTrial-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('AktivGrotesk-Medium.eot');
    src: url('AktivGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('AktivGrotesk-Medium.woff2') format('woff2'),
        url('AktivGrotesk-Medium.woff') format('woff'),
        url('AktivGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('AktivGroteskTrial-SemiBold.eot');
    src: url('AktivGroteskTrial-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AktivGroteskTrial-SemiBold.woff2') format('woff2'),
        url('AktivGroteskTrial-SemiBold.woff') format('woff'),
        url('AktivGroteskTrial-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('AktivGroteskTrial-Bold.eot');
    src: url('AktivGroteskTrial-Bold.eot?#iefix') format('embedded-opentype'),
        url('AktivGroteskTrial-Bold.woff2') format('woff2'),
        url('AktivGroteskTrial-Bold.woff') format('woff'),
        url('AktivGroteskTrial-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}