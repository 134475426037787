.networkPage .card {
    background-color: var(--white);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 1px 70px 55px -50px;
    overflow: hidden;
    margin-bottom: 16px;
    padding: 20px 15px 0;
}

.networkPage .nav-tabs {
    border: 0;
}

.networkPage .nav-tabs .nav-link {
    color: var(--black);
    border: 1px solid #ddd;
    padding: 12px 28px;
    border: 0;
    background: #e7e7e7;
    margin-right: 2px;
}

.networkPage .nav-tabs .nav-link.active {
    background: var(--primary);
    color: #fff;
}

.networkPage .table {
    margin: 0;
}

.networkPage .table>tbody>tr:last-child>td {
    border: 0;
}

.networkPage .table th {
    background-color: #f8f8f8;
    height: 55px;
    vertical-align: middle;
}

.networkPage .table th,
.networkPage .table td {
    padding: 10px 15px;
}

.networkPage .table {
    min-width: 510px;
}

.networkPage .page-link.active,
.networkPage .active>.page-link {
    background: var(--primary);
    border-color: var(--primary);
}

.page-link {
    background-color: var(--white);
}



@media (prefers-color-scheme: dark) {
    .networkPage .table th {
        background-color: #212121;
        color: #fff;
        border-bottom-color: #212121;
    }

    .networkPage .table td {
        background-color: #333;
        color: #fff;
        border-bottom-color: #212121;
    }

    .page-link {
        border-color: #212121;
        color: #fff;
    }
}