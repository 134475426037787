.loginContainer.networkPage {
    background: var(--white);
}
.store_detail_label {
    display: inline-block;
    width: 150px;
    vertical-align: top;
    padding-right: inherit;
}

span.badge {
    font-weight: 600;
}
.form-control.small{
    height: 40px;
}
.small.datepicker {
    font-size: 13px;
    height: 30px;
    max-width: 140px;
}
.accordion-button:focus {
    box-shadow: none;
}
p.error{
    color: red;
}
.admin_header a{
    border-bottom: transparent 1px solid !important;
    border-radius: 0;
}
.admin_header a.active{
    border-bottom: #000 1px solid !important;
}
.w-100 .react-datepicker-wrapper{
    width: 100%;
}
