@import url(./Assets/Fonts/stylesheet.css);

:root {
  --primary: #f1592a;
  --primaryhover: #d53f10;
  --black: #000;
  --darkblue: #060d29;
  --white: #fff;
  --whitetint1: #f8f8f8;
  --whitetint2: #f9f9f9;
  --grey: #827f7f;
  --greytint2: #8d8d8d;
  --bodyFont: 'Aktiv Grotesk';
  --input: #fff;
  --light: #f5f5f5;
}

html,
body,
#root,
#root>div {
  min-height: 100vh;
}


body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  font-family: var(--bodyFont), "Helvetica Neue", "Helvetica", Helvetica, Arial;
  color: var(--black);
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  color: var(--white);
  background-color: var(--primary);
}

::selection {
  color: var(--white);
  background-color: var(--primary);
}


* {
  scrollbar-width: thin;
  scrollbar-color: #b1b1b1 var(--light);
}

*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: var(--light);
}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 20px;
  border: 1px solid var(--light);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}


a {
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

button {
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}


/* a:hover {
  color: var(--primaryHover);
} */

img {
  max-width: 100%;
  height: auto;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

a:hover,
a:focus,
button,
button:hover,
button:focus,
input:focus,
textarea:focus,
select:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--black) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--input) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

details {
  display: block;
}

summary {
  display: list-item;
}


[hidden] {
  display: none !important;
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
}

button {
  cursor: pointer;
}

code,
pre {
  font-family: "SFMono-Regular", "SF Mono", "Ubuntu Mono", "Consolas", "DejaVu Sans Mono", "Menlo", monospace;
}

/* login */

.cardBox {
  width: 100%;
  border-radius: 5px;
  background: var(--white);
  border: 1px solid var(--black);
  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.1);
}


.cardBox__login {
  padding: 40px;
  margin: 0 auto 40px;
  width: 650px;
  max-width: calc(100% - 30px);
}

.loginLogo {
  margin-bottom: 30px;
}

.loginHeading {
  font-size: 24px;
  color: var(--black);
  margin-bottom: 30px;
}

.form-control {
  height: 50px;
  border-color: var(--black);
  color: var(--black);
  font-size: 14px;
  background-color: var(--input);
}

div.form-control {
  line-height: 48px;
  padding: 0 0.75rem;
}

.form-control.sm {
  height: auto;
  font-size: 12px;
  padding: 0.5rem 1rem;
}

.form-control.error {
  border-color: red;
}

.form-control>input {
  border: 0 !important;
  background: var(--input);
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--black);
  background: var(--input);
  color: var(--black);
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--greytint2);
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--greytint2);
  font-weight: 400;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--greytint2);
  font-weight: 400;
}

.loginHeading__forgotpassword {
  color: var(--greytint2);
  margin-bottom: 19px;
  display: inline-block;
}

.loginHeading__forgotpassword:hover {
  color: var(--primaryhover);
}

.btn:focus {
  box-shadow: none !important;
}

.btn.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  font-weight: 500;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: 0.3;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active {
  background: var(--primaryhover);
  border-color: var(--primaryhover);
  box-shadow: none;
  /* color: var(--primaryhover); */
}
.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary:active {
  color: var(--black);
  background-color: transparent;
}

.cardBox__login .btn {
  min-height: 54px;
  height: auto;
  line-height: 40px;
}

.orLoginWith {
  text-align: center;
  position: relative;
  font-size: 16px;
  margin-bottom: 20px;
}

.orLoginWith::before {
  content: '';
  width: 100%;
  height: 1px;
  background: var(--greytint2);
  position: absolute;
  left: 0;
  top: 50%;

}

.orLoginWith span {
  background: var(--white);
  display: inline-block;
  padding: 0 18px;
  font-size: inherit;
  position: relative;
  z-index: 1;
}

.loginWithOption {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.loginWithOption__btn {
  background: transparent;
  border-radius: 5px;
  border: 1px solid var(--black);
  height: 52px;
}

.loginWithOption__btn:hover {
  color: var(--primaryhover);
}

.loginWithOption__btn img {
  width: 24px;
  height: 24px;
  margin-right: 11px;
}

.loginContainer {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: rgb(249, 249, 249);
  background: linear-gradient(0deg, rgba(249, 249, 249, 1) 50%, rgba(255, 255, 255, 1) 50%);
}

.signUpLink a:not(.btn) {
  color: var(--primary);
}

.signUpLink a:not(.btn):hover {
  color: var(--primaryhover);
}

.customPhone .react-tel-input .form-control {
  border-color: var(--black);
  color: var(--black);
  font-size: 16px;
  width: 100%;
  height: 50px;
  border-radius: 0.375rem;
  padding-left: 10px;
  background: var(--input);
}

.customPhone .react-tel-input .flag-dropdown {
  border-color: var(--black);
  /* border-right: 0; */
  background: var(--white);
  border-radius: 0.375rem 0 0 0.375rem;
  display: none;
}

.cardBox.cardBoxStore {
  padding: 0;
  /* min-height: 680px; */
  display: flex;
  flex-direction: column;
}

.cardBoxStore__footer {
  margin-top: auto;
  padding: 20px 0px;
  border-top: 1px solid #e5e5e5;
}

.cardBoxStore__header {
  padding: 30px 0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}

.cardBoxStore__header .loginLogo {
  margin: 0;
}

.cardBoxStore__container {
  width: 100%;
  max-width: calc(100% - 70px);
  margin: 0 auto;
}

.cardBoxStore__body {
  padding: 30px 0;
}

.cardBoxStore__bodyHeading {
  margin-bottom: 30px;
}

.previousStoreBtn {
  padding: 15px;
}

.previousStoreBtn__contain {
  text-align: left;
}

.previousStoreBtn__heading {
  font-size: 18px;
  font-weight: 500;
}

.previousStoreBtn__content {
  font-size: 14px;
  color: var(--greytint2);
  line-height: normal;
}

.cardBoxStore__bodyScroll {
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
  min-height: 230px;
}

.createStoreBtn {
  font-size: 16px;
  color: var(--greytint2);
}

.btn-text {
  border: 0;
  padding: 0;
  font-size: 16px;
  color: var(--black);
  background: transparent;
  padding: 0 5px;
}

.btn-text-primary {
  color: var(--primary);
}

.btn-text-danger {
  color: var(--bs-red);
}

.inline_logout {
  display: inline-flex;
  align-items: center;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}


.stepFormButtonHolder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 17px;
}

.font-18 {
  font-size: 18px;
}

.text-greytint2 {
  color: var(--greytint2);
}

/* bx-chevron-down.svg */
.form-select-outer {
  position: relative;
}

.form-select-outer:after {
  content: "";
  background-image: url(./Assets/Images/bx-chevron-down.svg);
  background-size: 25px;
  background-position: calc(100% - 7px) 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 99;
  transform: translateY(-50%);
  pointer-events: none;
}

select.form-control {
  padding-right: 37px;
}

.stepIndicatior {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.stepIndicatior li {
  flex-grow: 1;
  list-style-type: none;
}

.stepIndicatior li span {
  background: var(--primary);
  width: 0;
  height: 2px;
  transition: 0.4s ease-in-out;
  display: block;
}

.stepIndicatior li.active span {
  width: 100%;
}

/*  */
.verifyPage {
  min-height: 100vh;
  /* background: rgb(249, 249, 249);
  background: linear-gradient(0deg, rgba(249, 249, 249, 1) 50%, rgba(255, 255, 255, 1) 50%); */
}

.verifyPage__container {
  /* max-width: 600px;
  width: calc(100% - 30px); */
}

.verifyPage__content {
  padding: 15px 0 10px;
}

.custom_alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  background: #62a965;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 2px 2px 10px;
  min-width: 200px;
  text-align: center;
  font-weight: 500;
  animation: alert 4.1s linear;
  z-index: 99999;
}

.custom_alert p::first-letter {
  text-transform: uppercase;
}

.custom_alert.error {
  background: red;
}

@keyframes alert {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -100%);
  }

  5% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  94% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}

.custom_alert p {
  margin: 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading>div {
  text-align: center;
}

.flex_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.flex_input input.form-control {
  padding-right: 45px;
}

.form-addon-icon {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 15px;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
}

.error-text {
  color: #ff0000 !important;
}

.success-text {
  color: #54d55a !important;
}

.password_note {
  padding: 0 10px 0;
}

.password_note li {
  font-size: 12px;
  line-height: 1.7;
}

.form-control.required {
  box-shadow: inset #f1592a -3px 0px 0px 0px !important;
}

.form-control.filled {
  box-shadow: inset #00c90f -3px 0px 0px 0px !important;
}

.suggestion_list {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.suggestion_block {
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.suggestion_list li {
  display: inline-block;
  margin-right: 5px;
}

.suggestion_list li button {
  border: 0;
  padding: 5px 10px;
  width: 100%;
  background-color: transparent;
  color: #0012e7;
  text-align: left;
  border-radius: 5px;
}

.suggestion_list li button:hover {
  /* text-decoration: underline !important; */
  background-color: #eee;
}

.password_bar {
  position: absolute;
  bottom: 1px;
  left: 2px;
  width: calc(100% - 4px);
  height: 5px;
  border-radius: 0 0 0.375rem 0.375rem;
  background-color: #eee;
  overflow: hidden;
}

.password_bar span {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}


.status_tab_panel {
  margin-bottom: 15px;
  border-bottom: #eee 1px solid;
}

.status_tab_panel button {
  border-bottom: 3px solid transparent;
  padding: 10px 15px;
  margin-right: 10px;
  color: var(--grey);
}

.status_tab_panel button.active {
  border-bottom-color: var(--primary);
  color: var(--primary);
}

.cardBox__login .expired * {
  opacity: 0.8;
}

.activity_logs {
  position: relative;
  padding-left: 15px;
}

.activity_logs_item:not(:last-child):before {
  content: "";
  background: #999;
  position: absolute;
  top: 13px;
  left: -15px;
  width: 2px;
  height: calc(100% + 15px);
  z-index: 0;
}

.activity_logs_item {
  list-style: disc;
  position: relative;
  word-break: break-all;
}

/* .cardBox__login .btn:hover {
  background: var(--whitetint1);
} */

.linear_progress {
  display: block;
  background-color: #e6e6e6;
  width: 100%;
  height: 5px;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.linear_progress_bar {
  background-color: var(--primary);
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: bar linear infinite 2s;
}

@keyframes bar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(500%);
  }
}

.loader_outer {
  display: inline-block;
  margin: 30px 0px 0px;
  width: 100%;
  position: relative;
}

.font-20 {
  font-size: 20px;
}


.view_json_block {
  padding: 10px;
  border: #eee 1px solid;
  border-radius: 5px;
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}
.btn:hover, .btn:hover * {
  color: var(--bs-btn-hover-color);
}
.list-group-item {
  color: var(--black);
}
.form_group .input-group-text {
  background: no-repeat;
  border-color: #000;
}
.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
}

@media only screen and (max-width:575px) {
  .cardBox__login {
    padding: 40px 30px;
  }

  .loginContainer {
    padding: 30px 0;

  }

  .cardBoxStore__header {
    padding: 30px 0;
  }

  .cardBoxStore__footer {
    padding: 20px 0px;
  }
}

@media only screen and (max-width:480px) {
  .cardBox__login {
    padding: 30px 15px;
  }

  .cardBoxStore__container {
    max-width: calc(100% - 30px);
  }

  .loginWithOption__btn img {
    margin-right: 3px;
  }
}


@media (prefers-color-scheme: dark) {
  :root {
    --bs-body-bg: #2a2a2a;
    --white: #000;
    --black: #fff;
    --input: #494949;
    --whitetint1: #2a2a2a;
    --whitetint2: #1a1a1a;
    --light: #1a1a1a;
  }
  .modal-header .btn-close{
    filter: invert(1);
  }

  .loginContainer {
    background: linear-gradient(0deg, rgba(38, 38, 38, 1) 50%, rgba(0, 0, 0, 1) 50%);
  }

  .form-select-outer:after {
    filter: invert();
  }

  .btn.btn-secondary:hover,
  .btn.btn-secondary:focus,
  .btn.btn-secondary:active {
    color: var(--black);
  }
  .react-tel-input .form-control.invalid-number{
    background-color: #400000 !important;
  }
  .suggestion_block{
    background-color: #262626;
  }
  .suggestion_list li button {
    color: #818bff;
}
}